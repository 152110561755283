<template>
  <div class="row">
    <div class="col-md-4">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.REISEKUERZEL'"></span>
        <span class="text-muted">{{ item.reisekuerzel }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.PM'"></span>
        <span>{{ $store.getters.getUsernameById(item.pmId) }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.DOM'"></span>
        <span>{{ $store.getters.getUsernameById(item.domId) }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.COUNTRY'"></span>
        <span class="text-muted">{{ $t('REISEN.COUNTRIES.' + item.country) }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2" v-t="'REISEN.COMMON.REGION'"></span>
        <span class="text-muted">{{ $t('REISEN.REGIONS.' + item.region) }}</span>
      </div>
    </div>
    <div class="col-md-5">
      <div class="d-flex justify-content-between mb-2">
        <template v-if="item.reisetermine.length > 0">
          <ul>
            <li
              class="d-flex align-items-center justify-content-between"
              v-for="(item, i) in item.reisetermine"
              :key="i"
            >
              <b-link
                :to="{
                  name: 'reisetermine-view',
                  params: { reiseterminId: item.id },
                }"
              >
                {{ item.id }} | PAX: {{ item.pax.ist }}</b-link
              >
            </li>
          </ul>
        </template>
      </div>
      <LoadSpinnerWidget
        class="col-md-12"
        :dataAvailable="item.reisetermine.length > 0 ? true : false"
        :isLoading="isLoading"
      ></LoadSpinnerWidget>
    </div>
  </div>
</template>

<style lang="scss">
.more-btn {
  cursor: pointer;
}
</style>

<script>
import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';

export default {
  name: 'ReisenTableDetails',
  components: { LoadSpinnerWidget },
  props: {
    item: {},
  },
  data() {
    return {
      vorgaenge: [],
      isLoading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = false;
    },
  },
};
</script>
